<template>
  <div class="headerTop-links py-3" :class="[{ 'sticky' : isScroll }, mediaSize]" v-if="mediaSize === 'small-size'">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="list-inline mb-0 d-flex flex-wrap header-nav-wrapper" >
            <li class="list-inline-item d-flex topCallUs" :class="rtlClassName" style="place-items: inherit;" v-if="!isFCLAgent">
              <b-button class="extra-phones" :href="`tel:${phoneNumber}`"><i class="fas fa-phone-alt"></i> &nbsp;{{ $t("top-header.service-center") }}</b-button>
              <!-- <b-dropdown variant="primary" size="sm" ref="dropdown" no-caret>
                <template #button-content>
                  <i class="fas fa-phone-alt"></i> &nbsp;{{ $t("top-header.service-center") }}
                </template>
                <b-dropdown-item :href="`tel:${phoneNumber}`">
                  <div class="display-flex">
                    <div class="pl-0 pr-2 py-1">
                      <b-button class="w-100" :href="`tel:${phoneNumber}`" size="sm" variant="primary">{{ phoneNumber }}: {{ $t("top-header.vacation-in-israel") }}</b-button>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item :href="`tel:${whiteLabel.vacationPhoneNumber}`" v-if="showVacationPhone">
                  <div class="display-flex">
                    <div class="pl-0 pr-2 py-1">
                      <b-button class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" size="sm" variant="primary">{{ whiteLabel.vacationPhoneNumber }}: {{ $t("top-header.vacation-abroad") }}</b-button>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown> -->
            </li>
            <li class="topLinkPack" :class="rtlClassName" style="place-items: inherit;">
              <button @click="gotoPackSite" size="sm" variant="primary">{{ $t("top-header.vacation-abroad") }}</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue';
import TemplateTopHeader from './TemplateTopHeader';

export default {
  name: 'TopHeaderTheme2',
  mixins: [TemplateTopHeader],
  components: {
    BButton,
    // BDropdown,
    // BDropdownItem,
  },
};
</script>

<style lang="scss">
  .headerTop-links.small-size{
    background: red !important;
    padding: 0px !important;
    .header-nav-wrapper{
      height: 38px;
      margin-left: 40px;
    }

    ul {
      margin: auto;
      padding: 0;
      justify-content: center;

      li{
        margin: auto !important;
      }
    }

    .btn.dropdown-toggle {
      background: red !important;
      border: 0 !important;
      font-weight: bold;
      &:hover{
        background: red !important;
        color: yellow;
      }
    }

    .topLinkPack{
      button{
        background: red;
        border: none;
        color: white;
        font-weight: bold;
      }
    }
    .extra-phones {
      padding: 2px 10px;
      border-radius: 15px;
      border: 1px solid #fff;
    }
  }
</style>
