import { mapGetters } from 'vuex';
import agencyMarketer from '@/mixins/agencyMarketer';

export default {
  name: 'TemplateTopHeader',
  mixins: [agencyMarketer],
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLoading: 'GET_LOADING_STATE',
      device: 'GET_DEVICE',
      mediaSize: 'GET_MEDIA_SIZE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      landingInfo: 'GET_LANDING_INFO',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      agencyUser: 'GET_AGENCY_USER',
      odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
      currentPage: 'GET_CURRENT_PAGE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
    dealTypeCode() {
      const { query } = this.$route;
      return query.destination || '';
    },
    destinationName() {
      const { destinationList } = this;
      if (!destinationList) return '';
      return destinationList.find((item) => item.code === this.dealTypeCode).name;
    },
    chainName() {
      return this.isLandingChain ? this.landingInfo.chainName : '';
    },
    utm_source() {
      const { query } = this.$route;
      return query.utm_source || '';
    },
    isNotHomePage() {
      return this.currentPage !== 'home';
    },
    logoURL() {
      const { marketerId } = this;
      return marketerId === '' ? '/' : `/?marketerId=${this.marketerId}`;
    },
    // hostData() {
    //   let currHostData = null;
    //   if (this.marketerId !== '') {
    //     currHostData = this.marketerAgencyContent;
    //   } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
    //     currHostData = this.whiteLabel;
    //   }
    //   return currHostData;
    // },
    showFacebookLink() {
      return this.marketerId === '' || !!this.hostData?.facebookLink;
    },
    facebookLink() {
      const defaultLink = 'https://he-il.facebook.com/flying.co.il/';
      const link = this.hostData?.facebookLink ? `http://www.facebook.com/${this.hostData?.facebookLink.replace('http://www.facebook.com/', '')}` : defaultLink;
      return link;
    },
    showInstagramLink() {
      return this.marketerId === '' || !!this.hostData?.instagramLink;
    },
    instagramLink() {
      const defaultLink = 'https://www.instagram.com/flyingcarpet_il/';
      const link = this.hostData?.instagramLink ? `https://www.instagram.com/${this.hostData?.instagramLink.replace('https://www.instagram.com/', '')}` : defaultLink;
      return link;
    },
    showTwitterLink() {
      return this.marketerId === '' || !!this.hostData?.twitterLink;
    },
    twitterLink() {
      const defaultLink = 'https://twitter.com/@flyingcarpetltd';
      const link = this.hostData?.twitterLink ? `https://twitter.com/${this.hostData?.twitterLink.replace('https://twitter.com/', '')}` : defaultLink;
      return link;
    },
    showVacationPhone() {
      // let showPhone = true;
      // if (this.marketerId !== '') {
      //   showPhone = this.marketerAgencyContent?.showDomesticTourismLink;
      // }
      const showPhone = this.marketerId === '';
      return showPhone;
    },
  },
  watch: {
    lang() {
      this.changeRtl();
    },
  },
  data() {
    return {
      rtlClassName: '',
      isScroll: true,
      tzNumber: '',
      tzNumberCheckResult: null,
    };
  },
  mounted() {
    this.changeRtl();
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    changeRtl() {
      if (this.lang === 'he') {
        this.rtlClassName = 'phone-number-rtl';
      } else {
        this.rtlClassName = '';
      }
    },
    onScroll(event) {
      if (this.$refs.dropdown) this.$refs.dropdown.hide();
      this.isScroll = Number(event.target.scrollTop) > 150;
    },
    async agencyLogout() {
      this.$bvModal.msgBoxConfirm(this.$t('login.log-out-confirm-box-title'), {
        title: this.$t('login.confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('login.yes'),
        cancelTitle: this.$t('login.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async (value) => {
          if (value) await this.$store.dispatch('REQUEST_AGENCY_LOGOUT');
          // if (this.odysseyAgentState) { window.location.href = 'https://www.flying-carpet.co.il'; }
        });
    },
    gotoExternalPage() {
      if (window.location.host.includes('localhost')) {
        this.$router.push({ path: `/external-booking?lang=${this.lang}` });
      } else {
        const routeData = this.$router.resolve({ path: '/external-booking' });
        window.open(routeData.href, '_blank');
      }
    },
    gotoCancelPage() {
      if (window.location.host.includes('localhost')) {
        this.$router.push({ path: `/cancel-booking?lang=${this.lang}` });
      } else {
        const routeData = this.$router.resolve({ path: `/cancel-booking?${this.lang}` });
        window.open(routeData.href, '_blank');
      }
    },
    gotoPackSite() {
      window.open('https://www.flying.co.il', '_blank');
    },
    showBookingCheckModal() {
      this.$bvModal.show('checkTzNumber');
    },
    async requestTzNumber() {
      const currentYear = new Date().getFullYear();
      let alreadyUsed = false;
      if (this.tzNumber) {
        this.tzNumberCheckResult = await this.$store.dispatch('FETCH_TZNUMBER_CHECK_RESULT', { tzNumber: this.tzNumber });
        alreadyUsed = this.tzNumberCheckResult.usersHistoryList.filter((lst) => lst.year === currentYear)?.findIndex((lst) => lst.status) > -1;
        this.tzNumberCheckResult.isApproved = this.tzNumberCheckResult.result && !alreadyUsed;
        this.tzNumberCheckResult.usersHistoryList.sort((a, b) => b.year - a.year);
      }
    },
  },
};
